
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands, getUuid } from "@/utils/common";
import dayjs from "dayjs";
import { Pageniation as Table } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const learning = namespace("learning");
const base = namespace("base");
@Component({
  components: {},
  filters: {
    NameListFilter: val => {
      return val ? val.join("/") : "-";
    }
  }
})
@Table("loadListData")
export default class InteractionList extends Vue {
  @learning.Action searchLearnInvitationPage;
  @learning.Action queryInvitationClassList;
  @learning.Action deleteLearnInvitation;
  @learning.Action createLearnInvitation;
  @learning.Action thumbsUpLearnInvitation;

  @base.Action getDictionaryList;
  @base.Action getUploadToken;
  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;

  @Watch("searchValue")
  searchValueWatch() {
    if (this.searchValue === "") {
      this.searchFlag = false;
      this.loadListData();
    }
  }
  isShowDelete: any = false;
  isShowRelease: any = false;
  releaseForm: any = {
    invitationTitle: "",
    invitationText: "",
    invitationClassCode: "",
    anonymousPublish: "false",
    annexList: []
  };
  classifyList: any = [];
  contentList: any = [];
  classifyCode: any = "";
  searchValue: any = "";
  searchFlag: any = false;
  currentItemCode: any = "";
  releaseFormRules: any = {
    invitationTitle: [
      { required: true, message: t("v210801.please-enter-the"), trigger: "blur" },
      { min: 4, message: t("v210831.minimum-4-characters"), trigger: "blur" },
      { max: 50, message: t("v210831.up-to-50-max"), trigger: "blur" }
    ],
    // invitationText: [
    //   { required: true, message: "请输入内容", trigger: "blur" },
    //   { max: 150, message: "最多150个字符", trigger: "blur" }
    // ],
    invitationClassCode: [{ required: true, message: t("v210831.please-select-a-class-post"), trigger: "change" }]
  };
  created() {
    this.init();
  }
  async init() {
    this.getClassifyList();
    this.loadListData();
  }
  getSrcList(urls, index) {
    let newList = urls.map(v => v.annexUrl);
    return newList.slice(index).concat(newList.slice(0, index));
  }
  // 搜索帖子标题
  handelQuery() {
    if (this.searchValue === "") {
      this.searchFlag = false;
    } else {
      this.searchFlag = true;
    }
    this.loadListData();
  }
  // 跳转帖子详情
  toInteractionView(item) {
    let routeData = this.$router.resolve({
      path: "/learning/interaction-view/" + item.invitationCode
    });
    window.open(routeData.href, "_blank");
  }
  // 帖子分类列表
  getClassifyList() {
    this.queryInvitationClassList({
      invitationClassName: ""
    }).then(data => {
      this.classifyList = data.data;
    });
  }
  // 帖子列表
  @Table.LoadDataMethod()
  loadListData() {
    return this.searchLearnInvitationPage({
      invitationClassCode: this.classifyCode,
      searchKey: this.searchValue,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.contentList = data.data.list;
      return data;
    });
  }
  // 选择帖子分类
  selectClassify(item) {
    if (item) {
      this.classifyCode = item.invitationClassCode;
    } else {
      this.classifyCode = "";
    }
    this.loadListData();
  }
  // 删除帖子按钮
  clickDelete(item) {
    this.isShowDelete = true;
    this.currentItemCode = item.invitationCode;
  }
  // 删除帖子
  handleDelete() {
    this.isShowDelete = false;
    this.deleteLearnInvitation({
      invitationCode: this.currentItemCode
    }).then(data => {
      this.$message.success(t("v210831.the-post-was-delete-seccess"));
      this.loadListData();
    });
  }
  // 发布帖子
  handleRelease() {
    (this.$refs.releaseForm as any).validate(valid => {
      if (valid) {
        this.createLearnInvitation(this.releaseForm).then(data => {
          this.$message.success(t("v210831.published-successfully"));
          this.isShowRelease = false;
          this.releaseForm.invitationTitle = "";
          this.releaseForm.invitationText = "";
          this.releaseForm.annexList = [];
          this.loadListData();
        });
      }
    });
  }
  // 点赞帖子
  handlePraise(item) {
    if (item.thumbsUpBySelf === "true") {
      this.$message.warning(t("v210831.you-like-it"));
      return;
    }
    this.thumbsUpLearnInvitation({
      invitationCode: item.invitationCode
    }).then(data => {
      this.$message.success(t("v210831.praise-succeeded"));
      this.loadListData();
    });
  }
  // 上传图片
  uploadUrl: string = "";
  uploadTokenObj: any = {};
  generatorBusinessCode() {
    return {
      businessType: "0",
      businessCode: getUuid(15)
    };
  }
  async beforeAvatarUpload(file) {
    if (this.releaseForm.annexList.length >= 9) {
      this.$message.warning(t("v210831.you-can-only-9img"));
      return;
    }
    // let type = file.type.split("/");
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      this.$message.warning(t("v210831.please-select-png"));
      return;
    }
    return this.getUploadToken(this.generatorBusinessCode()).then(data => {
      this.uploadTokenObj = {
        url: data.data.host,
        key: `${data.data.dir}/${file.name}`,
        policy: data.data.policy,
        OSSAccessKeyId: data.data.accessId,
        Signature: data.data.signature,
        success_action_status: 200,
        domain: data.data.domain
      };
      this.uploadUrl = data.data.host;
    });
  }
  handleUploadSuccess(response, file, fileList) {
    if (this.releaseForm.annexList.length >= 9) {
      return;
    }
    let type = file.raw.type.split("/");
    if (file.status === "success" && (file.raw.type === "image/png" || file.raw.type === "image/jpeg")) {
      this.releaseForm.annexList.push({
        annexName: file.name,
        annexType: type[1],
        annexUrl: this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key
      });
    }
  }
  delelteImg(item) {
    this.releaseForm.annexList = this.releaseForm.annexList.filter(v => {
      return v.annexUrl !== item.annexUrl;
    });
  }
}
